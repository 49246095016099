import { Component, Input, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category/category.service';
import { DibcHttpService } from 'src/app/services/dibcHttp/dibc-http.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  @Input()
  cateId: number
  category: any = {}
  constructor(
    private cateServ: CategoryService,
    public dibcHttp: DibcHttpService) { }

  async ngOnInit() {
    this.category = await this.cateServ.getCategory(this.cateId);
  }

}
