import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'language',
    loadChildren: () => import('./pages/language/language.module').then(m => m.LanguagePageModule)
  },
  {
    path: 'profile-info',
    loadChildren: () => import('./pages/profile-info/profile-info.module').then(m => m.ProfileInfoPageModule)
  },
  {
    path: 'images',
    loadChildren: () => import('./pages/images/images.module').then(m => m.ImagesPageModule)
  },
  {
    path: 'prodcts/:cateId',
    loadChildren: () => import('./pages/prodcts/prodcts.module').then(m => m.ProdctsPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'new-product',
    loadChildren: () => import('./pages/new-product/new-product.module').then(m => m.NewProductPageModule)
  },

  {
    path: 'user-list',
    loadChildren: () => import('./pages/user-list/user-list.module').then(m => m.UserListPageModule)
  },
  {
    path: 'product-enquiry/:prodId',
    loadChildren: () => import('./pages/product-enquiry/product-enquiry.module').then(m => m.ProductEnquiryPageModule)
  },
  {
    path: 'enquiries',
    loadChildren: () => import('./pages/enquiries/enquiries.module').then(m => m.EnquiriesPageModule)
  },
  {
    path: 'my-enquiries',
    loadChildren: () => import('./pages/my-enquiries/my-enquiries.module').then(m => m.MyEnquiriesPageModule)
  },
  {
    path: 'message/:enqid/:productid/:userid',
    loadChildren: () => import('./pages/message/message.module').then(m => m.MessagePageModule)
  },
  {
    path: 'conversation',
    loadChildren: () => import('./pages/conversation/conversation.module').then(m => m.ConversationPageModule)
  },
  {
    path: 'user-products',
    loadChildren: () => import('./pages/user-products/user-products.module').then( m => m.UserProductsPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
