import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { User } from 'src/app/data-types/user';
import { LangService } from 'src/app/services/lang/lang.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginData = { mobile: '', otp: '' };
  waitingForOtp = false;
  user = new User();
  constructor(
    private userServ: UserService,
    private modalCtrl: ModalController,
    public langServ: LangService,
    private navCtrl: NavController
  ) { }

  async ngOnInit() {
    await this.userServ.user.subscribe(async u => {
      this.user = u;
    });
  }
  async login() {
    if (this.waitingForOtp) {
      if (!this.validateOtp()) {
        return;
      }
    } else {
      if (!this.validateMobile()) {
        return;
      }
    }
    const u = await this.userServ.login(this.loginData);
    if (u && this.waitingForOtp) {
      this.modalCtrl.dismiss();

    }
    console.log(u);
    if (u) {
      this.waitingForOtp = true;
    }
  }
  validateMobile() {
    return ((this.loginData.mobile + "").length == 10)
  }
  validateOtp() {
    return ((this.loginData.otp + "").length == 6)
  }
  async resetLoginForm() {
    this.loginData = { mobile: '', otp: '' };
    this.waitingForOtp = false;
    this.user = new User();
  }
  async closeModal() {
    await this.modalCtrl.dismiss({});
  }
}
