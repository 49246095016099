import { Component, OnInit } from '@angular/core';
import { NavController, MenuController, ModalController, AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { User } from 'src/app/data-types/user';
import { UserService } from 'src/app/services/user/user.service';
import { LoginComponent } from '../login/login.component';
import { LangService } from 'src/app/services/lang/lang.service';
import { LanguagePage } from 'src/app/pages/language/language.page';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  user = new User();
  msgCount = 0;
  subscription: Subscription;
  subscriptionMsg: Subscription;
  constructor(
    private userServ: UserService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    public langServ: LangService,
    public alertCtrl: AlertController) { }

  async ngOnInit() {
    this.subscription = await this.userServ.user.subscribe(async u => {
      this.user = u;
    });
  }
  async logout() {
    await this.userServ.logout();
    document.getElementsByClassName('menu-content-open').item(0).classList.remove('menu-content-open');
    setTimeout(() => {
      this.navCtrl.navigateRoot('/');
    }, 1000);
  }
  async openInternalLink(link) {
    await document.getElementsByClassName('menu-content-open').item(0).classList.remove('menu-content-open');
    this.navCtrl.navigateRoot(link);
  }
  async openExternalLink(link) {
    // await Browser.open({ url: link });
  }
  async openLoginForm() {
    const modal = await this.modalCtrl.create({
      component: LoginComponent,
      cssClass: 'small'
    });
    modal.onDidDismiss().then(async (resp) => {

    });
    await modal.present();
  }
  async openSignupForm() {
    const modal = await this.modalCtrl.create({
      component: LoginComponent,
      cssClass: 'small'
    });
    modal.onDidDismiss().then(async (resp) => {

    });
    await modal.present();
  }
  async langauge() {
    const modal = await this.modalCtrl.create({
      component: LanguagePage,
      cssClass: 'small'
    });
    modal.onDidDismiss().then(async (resp) => {
    });
    await modal.present();
  }

}
