export const langEnIn = {
    selectLanguge: "Select language",
    home: "Home",
    signup: "Sign Up",
    login: "Log In",
    profile: "Profile",

    changelng: "Change Language",
    logout: "Log out",
    hometitle: "Don't just think of your old book as useless. Use it for someone.",
    homesubtitle: "SO DONATE YOUR BOOK",
    category: "Category",
    mobile: "Mobile",
    otp: "OTP",
    welcome: "Welcome",

    uploadimg: "Upload Image",
    city: "City",
    state: "State",
    pincode: "Pin code",
    fulladdress: "Full Address",
    submit: "Submit",
    cancel: "Cancel",
    pinlength: "Please enter 6-digit Pincode",
    email: "Email",
    address: "Address",
    message: "Message",

    viewdtls: "View Details",
    ordernow: "Order Now",
    name: "Name",
    save: "Save",
    company: "Company",
    addressline1: "Address Line 1",
    addressline2: "Address Line 2",
    country: "Country",
    gst: "GST",
    type: "Type",
    tradingin: "Trading In",
    products: "Products",
    material: "Material",
    prodcode: "Code",
    prodweight: "Weight",
    prodimage: "Image",
    desc: "Description",
    enquiries: "Enquiries",
    myproduct: "My Products"
};