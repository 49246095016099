export const INT_URL = 'https://localhost/B2BJewelery-API/public/';
export const EXT_URL = 'https://b2bjewellery.dibcs.in/public/';
export const API_PATH = 'api/';
export const USER_API_PATH = 'user/';
export const PUB_API_PATH = 'pub/';
export const PRODUCT_API_PATH = 'product/'
// export const UPLOAD_PATH = 'act_uploads/';

export const UPLOAD_API_PATH = 'uploads/'
export const UPLOAD_PATH = 'uploads/image/';


export const REGEX_EMAIL = '^[a-zA-Z0-9.]+@[a-zA-Z0-9-]+[\.]+[a-zA-Z0-9]+(?:\.[a-zA-Z0-9-]+)*$';
export const REGEX_PHONE = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const REGEX_PASSWORD = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{4,}/;
export const REGEX_PAN = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
export const REGEX_GST = " \d{ 2}[A-Z]{ 5 } \d{ 4 } [A - Z]{ 1 } [A - Z\d]{ 1 } [Z]{ 1 } [A - Z\d]{ 1 }";
export const REGEX_PIN = "[1-9][0-9]{5}"
