import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductEnquiryPage } from 'src/app/pages/product-enquiry/product-enquiry.page';
import { CategoryService } from 'src/app/services/category/category.service';
import { DibcHttpService } from 'src/app/services/dibcHttp/dibc-http.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {

  @Input()
  prodId: number
  product: any = {}
  constructor(
    private prodServ: ProductService,
    public dibcHttp: DibcHttpService,
    private modalCtrl: ModalController
  ) { }

  async ngOnInit() {
    this.product = await this.prodServ.getProduct(this.prodId);
  }
  async prodenquiry(prodId) {
    const modal = await this.modalCtrl.create({
      component: ProductEnquiryPage,
      componentProps: { 'prodId': prodId },

      cssClass: 'small'
    });
    modal.onDidDismiss().then(async (resp) => {

    })
    await modal.present();
  }
}
