export const langHiIn = {
    home: "होम",
    signup: "साइन अप करें",
    login: "लॉग इन करें",
    profile: "प्रोफ़ाइल",
    addbook: "किताब जोड़ें",
    findbook: "पुस्तक खोजें",
    myorder: "माइ ऑर्डर",
    userorder: "यूजर ऑर्डर",
    mybook: "मेरी किताब",
    changelng: "भाषा बदलें",
    logout: "लॉग आउट",
    hometitle: "अपनी पुरानी किताब को यूं ही बेकार न समझें। इसे किसी के लिए इस्तेमाल करें।",
    homesubtitle: "तो अपनी किताब दान करें।",
    category: "कैटेगरी",
    books: "पुस्तकें",
    mobile: "मोबाइल",
    otp: "ओटीपी",
    welcome: "स्वागत हे ।",
    bookname: "पुस्तक का नाम",
    author: "लेखक",
    uploadimg: "तस्वीर डालिये",
    state: "राज्य",
    city: "शहर",
    pincode: "पिन कोड",
    fulladdress: "पूरा पता",
    submit: "प्रस्तुत करना",
    cancel: "रद्द करें",
    pinlength: "कृपया 6-अंकों का पिनकोड दर्ज करें।",
    email: "ईमेल",
    orderby: "आर्डर बाइ",
    message: "मैसेज",
    delivered: "डिलिवरड",
    bookdetails: "पुस्तक डिटेल्स",
    userdtls: "यूजर डिटेल्स",
    find: "फाइंड",
    requestdone: "रिक्वेस्ट डन",
    request: "रिक्वेस्ट",
    bookordered: "पुस्तक आर्डरड",
    viewdtls: "व्यू डिटेल्स",
    ordernow: "आर्डर नाउ",
    address: "एड्रेस",
    name: "नाम",
    save: "सेव"
};