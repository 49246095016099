import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang/lang.service';
import { ProductService } from 'src/app/services/product/product.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/data-types/user';
import { UserService } from 'src/app/services/user/user.service';
import { ModalController, NavController, NavParams, AlertController } from '@ionic/angular';
import { DibcHttpService } from 'src/app/services/dibcHttp/dibc-http.service';

@Component({
  selector: 'app-product-enquiry',
  templateUrl: './product-enquiry.page.html',
  styleUrls: ['./product-enquiry.page.scss'],
})
export class ProductEnquiryPage implements OnInit {
  prodId: any = '';
  user: User = new User();
  subscription: Subscription;
  product: any = {};
  prodenq: any = {};
  constructor(
    private prodServ: ProductService,
    public langServ: LangService,
    private userServ: UserService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public dibcHttp: DibcHttpService,
    private alertCtrl: AlertController
  ) {
    if (this.navParams.get('prodId')) {
      this.prodId = navParams.get('prodId');
      console.log(this.prodId);
    }
  }

  async ngOnInit() {
    this.subscription = await this.userServ.user.subscribe(async u => {
      this.user = u;
    });
    this.product = await this.prodServ.getProduct(this.prodId);
    console.log(this.product);
  }
  async closeModal() {
    await this.modalCtrl.dismiss({});
  }
  async submitform() {
    const valid = await this.formvalidation();
    if (valid == 'valid') {
      this.saveinfo();
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Validation Error',
        message: valid
      });
      setTimeout(() => {
        alert.dismiss();
      }, 2000);
      await alert.present();
    }
  }
  async saveinfo() {
    this.prodenq.productid = this.prodId;
    const resp = await this.prodServ.productEnquiry(this.prodenq);
    if (resp) {
      this.closeModal();
    }
  }

  async formvalidation() {
    if (!this.prodenq.qtn) {
      return 'Enter quantity.';
    }

    return 'valid';
  }
}
