export class User {
    user_id: number = 0;
    user_name: string = '';
    user_mobile: string = '';
    user_email: string = '';
    loggedIn: boolean = false;
    user_pincode: number = 0;
    user_address1: string = '';
    user_address2: string = '';
    user_company: string = '';
    user_state: string = '';
    user_city: string = '';
    async user() {

    }

    async profileIsComplete() {
        let complete = true;
        Object.values(this).forEach((val) => {
            if (val == '') {
                complete = false;
            }
        });
        return complete;
    }

};