import { Component, OnInit } from '@angular/core';

import { LangService } from 'src/app/services/lang/lang.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-language',
  templateUrl: './language.page.html',
  styleUrls: ['./language.page.scss'],
})
export class LanguagePage implements OnInit {

  allLang: any = [];
  constructor(
    public langServ: LangService,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.allLang = this.langServ.languages;
  }
  async changelanguage(langcode) {
    this.langServ.setLang(langcode);
    await this.modalCtrl.dismiss({});
  }
}
