import { Injectable } from '@angular/core';
import { langEnIn } from 'src/app/lang/en-in';
import { langHiIn } from 'src/app/lang/hi-in';
import { StorService } from '../storage/stor.service';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  defaultLang = 'en-in';
  currentLang = '';
  lang: any = {};
  languages = [
    { label: 'English India', code: 'en-in' },
    { label: 'Hindi', code: 'hi-in' }
  ];
  constructor(
    private storageServ: StorService) {
    this.getLang();

  }
  text(key) {
    let val = '';
    try {
      val = this.lang[key];
    } catch (e) { }
    return val;
  }
  async setLang(lang) {
    this.currentLang = lang;
    switch (this.currentLang) {
      case 'en-in':
        this.lang = langEnIn;
        break;
      case 'hi-in':
        this.lang = langHiIn;
        break;

      default:
        this.currentLang = this.defaultLang;
        this.lang = langEnIn;
        break;
    }
    await this.storageServ.set(
      'tssLang', this.currentLang);
  }
  async getLang() {
    if (!this.currentLang) {
      const value = await this.storageServ.get('tssLang');
      this.currentLang = value;
    }
    //this.currentLang = 'en-in';
    this.setLang(this.currentLang);
    return this.currentLang;
  }
}
