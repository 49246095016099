import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { LoginComponent } from "./login/login.component";
import { MenuComponent } from "./menu/menu.component";
import { UserMenuComponent } from "./user-menu/user-menu.component";
import { CategoryComponent } from "./category/category.component";
import { ProductComponent } from "./product/product.component";
import { UsersComponent } from "./users/users.component";
import { ProductScrollComponent } from "./product-scroll/product-scroll.component";


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        RouterModule,
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        MenuComponent,
        UserMenuComponent,
        CategoryComponent,
        ProductComponent,
        UsersComponent,
        ProductScrollComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        MenuComponent,
        UserMenuComponent,
        CategoryComponent,
        ProductComponent,
        UsersComponent,
        ProductScrollComponent

    ]
})

export class ComponentModule { }