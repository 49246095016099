import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-scroll',
  templateUrl: './product-scroll.component.html',
  styleUrls: ['./product-scroll.component.scss'],
})
export class ProductScrollComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
