import { Injectable } from '@angular/core';
import * as Constants from '../../res/app.constants';
import { DibcHttpService } from '../dibcHttp/dibc-http.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  product: any = {};
  constructor(
    private dibcHttp: DibcHttpService
  ) { }
  async getProducts(cateId, pgno) {
    const url = Constants.PUB_API_PATH + 'category_products/' + cateId + '/' + pgno;
    const resp = await this.dibcHttp.post(url, {});
    if (resp.products) {
      return resp.products;
    }
    return [];
  }
  async getProduct(prodId) {
    const url = Constants.PUB_API_PATH + 'get_product/' + prodId;
    const resp = await this.dibcHttp.post(url, {});
    if (resp.product) {
      this.product = resp.product;

      this.product.img = this.dibcHttp.UPLOADS + resp.product.prod_image + '/image.png';
      console.log(this.product.img);
      return this.product;
    }
    return {};
  }
  async addProduct(productdata) {
    const url = Constants.PRODUCT_API_PATH + 'add_product';
    const apiResp = await this.dibcHttp.post(url, productdata);
    return apiResp;
  }
  async addImages(imagedata) {
    const url = Constants.PRODUCT_API_PATH + 'add_image';
    const apiResp = await this.dibcHttp.post(url, imagedata);
    return apiResp;
  }
  async productEnquiry(prodenquirydata) {
    const url = Constants.PRODUCT_API_PATH + 'add_product_enquiry';
    const apiResp = await this.dibcHttp.post(url, prodenquirydata);
    return apiResp;
  }
  async productEnquiries() {
    const url = Constants.PRODUCT_API_PATH + 'product_enquiries';
    const resp = await this.dibcHttp.post(url, {});
    if (resp.enquiries) {
      return resp.enquiries;
    }
    return [];
  }
  async myEnquiries() {
    const url = Constants.PRODUCT_API_PATH + 'my_enquiries';
    const resp = await this.dibcHttp.post(url, {});
    if (resp.myenquiries) {
      return resp.myenquiries;
    }
    return [];
  }
}
