import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import * as Constants from '../../res/app.constants';
import { User } from '../../data-types/user'
import { AuthService } from '../auth/auth.service';
import { DibcHttpService } from '../dibcHttp/dibc-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userdata: any = '';
  mobile: any = '';
  public userObj = new User();
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(this.userObj);
  private authkey = '';
  constructor(
    private authServ: AuthService,
    private dibcHttp: DibcHttpService
  ) {
    this.init();
  }

  async init() {
    // console.log('init user service');
    this.authkey = await this.getAuthKey();
    if (this.authkey) {
      this.getUserProfile();
      this.userObj.loggedIn = true;
      this.user.next(this.userObj);
    }
  }
  async getAuthKey() {
    if (!this.authkey) {
      this.authkey = await this.authServ.getAuthkey();
    }
    console.log(this.authkey);
    return this.authkey;
  }
  async login(logindata) {
    const url = Constants.USER_API_PATH + 'login';
    const apiResp = await this.dibcHttp.post(url, logindata);
    if (apiResp.status == 200) {
      if (apiResp.user) {
        this.userObj = apiResp.user;
        this.userObj.loggedIn = true;
        this.user.next(this.userObj);
        return this.userObj;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  async logout() {
    this.authServ.clear();
    this.userObj = new User();
    this.user.next(this.userObj);
  }
  async getUserProfile() {
    if (this.userObj.user_name.length == 0) {
      await this.getUserProfileFromServer();
    }
    return this.userObj;
  }
  async getUserProfileFromServer() {
    const url = Constants.USER_API_PATH + 'get_user';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.status === 200) {
      this.userObj = respData.user;
      this.userObj['loggedIn'] = true;
      this.user.next(this.userObj);
    }
    return this.userObj;
  }
  async addprofiledata(userdata) {
    const url = Constants.USER_API_PATH + 'adduserdata';
    const apiResp = await this.dibcHttp.post(url, userdata);
    return apiResp;
  }
  async getUsers() {
    const url = Constants.USER_API_PATH + 'get_users';
    const respData = await this.dibcHttp.post(url, {});
    if (respData) {
      return respData.users;
    }
    return [];
  }
  async getUser(userId) {
    const url = Constants.USER_API_PATH + 'list_user/' + userId;
    const respData = await this.dibcHttp.post(url, {});
    if (respData) {
      return respData.userinfo;
    }
    return {};
  }
  async getConversations() {
    const apiResp = await this.dibcHttp.post('user/get_conversations', {});
    if (apiResp.conversations) {
      return apiResp.conversations;
    }
    return [];
  }
  async allmessage(userid) {
    let data = {
      userid: userid,
    }
    const apiResp = await this.dibcHttp.post('user/get_msg', data);
    if (apiResp.messages) {
      return apiResp.messages;
    }
    return [];
  }
  async sendMessage(msg) {
    const apiResp = await this.dibcHttp.post('user/send_message', msg);
    return apiResp;
  }
  async userIformation(userid) {
    let data = {
      userid: userid,
    }
    const apiResp = await this.dibcHttp.post('user/userinformation', data);
    if (apiResp.userinfo) {
      return apiResp.userinfo;
    }
    return {};
  }
  async userProducts() {
    const url = Constants.USER_API_PATH + 'user_products';
    const resp = await this.dibcHttp.post(url, {});
    if (resp.products) {
      return resp.products;
    }
    return [];
  }
  async uploadImage(data) {
    const url = Constants.USER_API_PATH + 'upload';
    const apiResp = await this.dibcHttp.post(url, data, true, 'multipart');
    if (apiResp.file_upload) {
      return {
        path: this.dibcHttp.BASE_URL + apiResp.file_upload,
        id: apiResp.file_id
      }
    } else {
      return false;
    }
  }
}
