import { Injectable } from '@angular/core';
import * as Constants from '../../res/app.constants';
import { DibcHttpService } from '../dibcHttp/dibc-http.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private dibcHttp: DibcHttpService
  ) { }
  async Getcategories() {
    const url = Constants.PUB_API_PATH + 'get_categories';
    const resp = await this.dibcHttp.post(url, {});
    if (resp.categories) {
      return resp.categories;
    }
    return [];
  }
  async getCategory(cateId) {
    const url = Constants.PUB_API_PATH + 'get_category/' + cateId;
    const resp = await this.dibcHttp.post(url, {});
    if (resp.category) {
      return resp.category;
    }
    return {};
  }
  async getCategories() {
    const url = Constants.PUB_API_PATH + 'Categories';
    const resp = await this.dibcHttp.post(url, {});
    if (resp.titlecategories) {
      return resp.titlecategories;
    }
    return [];
  }
  async getMaterials() {
    const url = Constants.PUB_API_PATH + 'get_materials';
    const resp = await this.dibcHttp.post(url, {});
    if (resp.materials) {
      return resp.materials;
    }
    return [];
  }
}
