import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  @Input()
  userId: number
  user: any = {}
  constructor(
    private userServ: UserService
  ) { }

  async ngOnInit() {
    this.user = await this.userServ.getUser(this.userId);
  }
}